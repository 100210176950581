import React from 'react';
import {Table, Button} from 'antd';
import {timestampFormat} from '../../Utils/DateUtil';
import Anchor from '../../Widgets/Anchor';

export default function BlogTable({
  records,
  loading,
  onItemClick,
  onDelete,
  mounted = false,
}) {
  const columns = [
    {
      title: '標題',
      render: (_, instance) => (
        <Anchor type="button" onClick={() => onItemClick(instance)}>{`${
          instance.title || '---'
        }`}</Anchor>
      ),
      width: 150,
      fixed: 'left',
    },
    {
      title: '標籤',
      render: (_, instance) => `${instance.label || ''}`,
      width: 140,
    },
    {
      title: '建立時間',
      render: (_, instance) => {
        return timestampFormat(instance.created);
      },
      width: 200,
    },
    {
      title: '更新時間',
      render: (_, instance) => {
        return timestampFormat(instance.updated);
      },
      width: 200,
    },
    {
      title: '刪除',
      render: (_, instance) => (
        <Button onClick={() => onDelete(instance)}>刪除</Button>
      ),
      width: 100,
    },
  ];

  const extraProps = mounted
    ? {
        scroll: {
          x: window.innerWidth - 200 - 40,
        },
      }
    : {};

  return (
    <Table
      loading={loading}
      columns={columns}
      dataSource={records}
      pagination={{pageSize: 20}}
      {...extraProps}
    />
  );
}
